import React, {useState} from 'react';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import {useTranslation } from 'react-i18next';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000058 from "./Svg00000058.js";
import thumbnail from './Thumbnails/Svg00000058.png';

const Svg00000058Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 200;
  const baseHeight = 220;

  //Name of the image
  const imgName = "Scissors";

  //Individual Functions
  const [rotation, setRotation] = useState (0);

  //Initial color
  const [background1, setBackground1] = useState ("#268044");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, rotation, background1, scale)=>{
    return <Svg00000058
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      rotation = {rotation}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div>
              <Palette 
                className="palette"
                background={background1} 
                onColorChange={setBackground1}
              />
              </div>
          </div>
          <div className="parameterComponents1">
            {t("Open")}
            <br></br>
            <br></br>
            <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={90}
                onChange={(value) => setRotation(value)}/>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, rotation, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, rotation, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000058Wrap;
