import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000038 from "./Svg00000038.js";
import thumbnail from './Thumbnails/Svg00000038.png';

const Svg00000038Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 160;
  const baseHeight = 120;

  //Default Values
  const defaultRect1x = 0;
  const defaultRect1w = 160;
  const defaultRect1y = 25;
  const defaultRect1h = 60;
  const defaultRect2x = 0;
  const defaultRect2w = 50;
  const defaultRect2y = 25;
  const defaultRect2h = 115;

  //Name of the image
  const imgName = "HPLC";

  //Initial color
  const [background1, setBackground1] = useState ("#222");
  const [background2, setBackground2] = useState ("#000");
  const [background3, setBackground3] = useState ("#eeeeee");

  //Individual Functions
  const [isBottleRack, setIsBottleRack] = useState (true);
  const [componentHeight, setComponentHeight] = useState (90);
  const [rect1x, setRect1x] = useState (defaultRect1x);
  const [rect1y, setRect1y] = useState (defaultRect1y);
  const [rect1w, setRect1w] = useState (defaultRect1w);
  const [rect1h, setRect1h] = useState (defaultRect1h);
  const [rect2x, setRect2x] = useState (defaultRect2x);
  const [rect2y, setRect2y] = useState (defaultRect2y);
  const [rect2w, setRect2w] = useState (defaultRect2w);
  const [rect2h, setRect2h] = useState (defaultRect2h);
  const [manufacturer, setManufacturer] = useState("")
  const minComponentHeight = 40;
  const fullComponentHeight = 200;

  const minXW = 0;
  const maxXW = 160;
  const minYH = 25;
  const maxYH = 115+componentHeight-90;


  //Size scale
  const [scale, setScale] = useState(1);

  //Preset
  const shimadzu1 = {name:"shimadzu1",bg1:"#222222",bg2:"#000000",bg3:"#eeeeee",r1x:0,r1w:160,r1y:25,r1h:60,r2x:0,r2w:50,r2y:25,r2h:115}
  const shimadzu2 = {name:"shimadzu2",bg1:"#C8C8C8",bg2:"#000000",bg3:"#1E1E1E",r1x:0,r1w:50,r1y:25,r1h:115,r2x:0,r2w:45,r2y:25,r2h:115}
  const waters1 = {name:"waters1",bg1:"#142850",bg2:"#000000",bg3:"#1E3C6E",r1x:0,r1w:105,r1y:25,r1h:115,r2x:0,r2w:0,r2y:25,r2h:25}
  const agilent1 = {name:"agilent1",bg1:"#000000",bg2:"#000000",bg3:"#EEEEEE",r1x:0,r1w:160,r1y:40,r1h:100,r2x:0,r2w:0,r2y:25,r2h:25}
  const hitachi1 = {name:"hitachi1",bg1:"#292B83",bg2:"#EEEEEE",bg3:"#EEEEEE",r1x:85,r1w:125,r1y:25,r1h:115,r2x:95,r2w:115,r2y:35,r2h:105}
  const thermo1 = {name:"thermo1",bg1:"#0080FF",bg2:"#000000",bg3:"#EEEEEE",r1x:150,r1w:155,r1y:25,r1h:115,r2x:0,r2w:100,r2y:25,r2h:115}


  //Preset Function
  const setDesignType =(designType)=> {
    setBackground1 (designType.bg1)
    setBackground2 (designType.bg2)
    setBackground3 (designType.bg3)
    setRect1x (designType.r1x)
    setRect1w (designType.r1w)
    setRect1y (designType.r1y)
    setRect1h (designType.r1h)
    setRect2x (designType.r2x)
    setRect2w (designType.r2w)
    setRect2y (designType.r2y)
    setRect2h (designType.r2h)
    setManufacturer (designType.name)
  }


  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsBottleRack =()=> {if (isBottleRack) {setIsBottleRack(false);} else {setIsBottleRack(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isBottleRack, componentHeight, rect1x, rect1y, rect1w, rect1h, rect2x, rect2y, rect2w, rect2h, background1, background2, background3, scale)=>{
    return <Svg00000038
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isBottleRack = {isBottleRack}
      componentHeight = {componentHeight}
      rect1x = {rect1x}
      rect1y = {rect1y}
      rect1w = {rect1w}
      rect1h = {rect1h}
      rect2x = {rect2x}
      rect2y = {rect2y}
      rect2w = {rect2w}
      rect2h = {rect2h}
      background1 = {background1}
      background2 = {background2}
      background3 = {background3}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                  <div className="parameterComponents1">
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="shimadzu1"}
                          onChange={()=>setDesignType(shimadzu1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="shimadzu2"}
                          onChange={()=>setDesignType(shimadzu2)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="waters1"}
                          onChange={()=>setDesignType(waters1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="agilent1"}
                          onChange={()=>setDesignType(agilent1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="hitachi1"}
                          onChange={()=>setDesignType(hitachi1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                      <label className="radiobuttonLabel">
                        <input type="radio"
                          name="DesignType"
                          className="hiddenRadiobutton"
                          checked={manufacturer==="thermo1"}
                          onChange={()=>setDesignType(thermo1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                  </div>
                  <br/>
                  <div className="parameterComponents1">
                    <div className='checkboxBox'>
                      <label className="checkboxLabel">
                          {t("Bottle Rack")}{'\u00A0'}
                          <input type="checkbox"
                          className="hiddenCheckbox2"
                          checked={isBottleRack}
                          onChange={()=>switchIsBottleRack()}
                          />
                          <span className="checkboxMark"></span>
                      </label>
                    </div>
                  </div>
                  <br/>
                  <div className="parameterComponents1">
                    <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                    />
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect1x}
                    min={minXW}
                    max ={maxXW}
                    onChange={(value) => setRect1x(value)}
                    value={rect1x}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect1w}
                    min={minXW}
                    max ={maxXW}
                    onChange={(value) => setRect1w(value)}
                    value={rect1w}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect1y}
                    min={minYH}
                    max ={maxYH}
                    onChange={(value) => setRect1y(value)}
                    value={rect1y}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect1h}
                    min={minYH}
                    max ={maxYH}
                    onChange={(value) => setRect1h(value)}
                    value={rect1h}/>
                  </div>

                  <div className="parameterComponents1">
                    <Palette 
                    className="palette"
                    background={background2} 
                    onColorChange={setBackground2}
                    />
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect2x}
                    min={minXW}
                    max ={maxXW}
                    onChange={(value) => setRect2x(value)}
                    value={rect2x}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect2w}
                    min={minXW}
                    max ={maxXW}
                    onChange={(value) => setRect2w(value)}
                    value={rect2w}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect2y}
                    min={minYH}
                    max ={maxYH}
                    onChange={(value) => setRect2y(value)}
                    value={rect2y}/>
                    <br/>
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultRect2h}
                    min={minYH}
                    max ={maxYH}
                    onChange={(value) => setRect2h(value)}
                    value={rect2h}/>
                  </div>
                  <div className="parameterComponents1">
                    <Palette 
                    className="palette"
                    background={background3} 
                    onColorChange={setBackground3}
                    />
                  </div>
                  <br/>
                  <div className="parameterComponents1">
                    {t("Component Height")}{'\u00A0'}
                    <br/>
                    <br/>
                    <ReactSlider
                      className="slider"
                      thumbClassName="sliderThumb"
                      trackClassName="sliderTrack"
                      defaultValue={90}
                      min={minComponentHeight}
                      max={fullComponentHeight}
                      onChange={(value) => setComponentHeight(value)}/>
                    </div>
                </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, isBottleRack, componentHeight, rect1x, rect1y, rect1w, rect1h, rect2x, rect2y, rect2w, rect2h, background1, background2, background3, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight+componentHeight-90) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isBottleRack, componentHeight, rect1x, rect1y, rect1w, rect1h, rect2x, rect2y, rect2w, rect2h, background1, background2, background3, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000038Wrap;
