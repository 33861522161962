const Svg00000057 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    const detector =(
        <g>
        <path d="m189 45 5-5h25l5.1 5v25l-5 5-25 0.01-5.1-5z" fill="url(#linearGradient1293)"/>
        <g fill="url(#linearGradient3224)">
        <path d="m194 65 10-0.04v5h-10z"/>
        <path d="m194 45 9.9 0.01 0.1 5-9.9-0.01z"/>
        <path d="m194 55h10v5h-10z"/>
        </g>
        <g fill="url(#linearGradient3291)">
        <path d="m209 45 9.9 0.01v5h-10z"/>
        <path d="m209 55 9.9-0.013v5h-10z"/>
        <path d="m209 65h10v5h-10z"/>
        </g>
        </g>
    )
    const transformDetector =props.isTq?"":"translate(-150, -27), scale(1.5)"
    
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient3222">
        <stop stopColor="#ccc" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1186">
        <stop stopColor="#b2b2b2" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1190">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#ddd" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1293" x1="177" x2="190" y1="55" y2="45" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#bbb" offset="0"/>
        <stop stopColor="#ddd" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3658" x1="20" x2="45" y1="60" y2="60" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1190"/>
        <linearGradient id="linearGradient3656" x1="53" x2="55" y1="94" y2="110" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient1192" x1="58" x2="55" y1="45" y2="60" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient3770" x1="105" x2="106" y1="59" y2="76" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient3774" x1="113" x2="110" y1="45" y2="60" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient3786" x1="176" x2="174" y1="29" y2="42" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient3939" x1="200" x2="199" y1="39" y2="46" gradientUnits="userSpaceOnUse" spreadMethod="reflect"  xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient607" x1="167" x2="168" y1="60" y2="72" gradientUnits="userSpaceOnUse" spreadMethod="reflect"  xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient790" x1="198" x2="199" y1="61" y2="71" gradientUnits="userSpaceOnUse" spreadMethod="reflect"  xlinkHref="#linearGradient1186"/>
        <linearGradient id="linearGradient3224" x1="180" x2="200" y1="40" y2="40" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient3222"/>
        <linearGradient id="linearGradient3291" x1="160" x2="180" y1="40" y2="40" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient3222"/>
        </defs>
        {props.isDetector?(
        <g transform={transformDetector}>
        {detector}
        </g>
        ):(<></>)}
        {props.isTq?(
        <>
        <g fill="#a6a6a6">
        <path d="m164 40 17 3.5 2 0.53s2 0 2 4-3 4-3 4h-18z"/>
        <ellipse cx="164" cy="46" rx="5" ry="6"/>
        </g>
        <g>
        <path d="m192 65h19s2 0 2 4-3 4-3 4l-18 4z" fill="url(#linearGradient790)"/>
        <ellipse cx="192" cy="71" rx="5" ry="6" fill="#999"/>
        </g>
        <g>
        <path d="m192 40 19 5s2 0 2 4-3 4-3 4l-18-1z" fill="url(#linearGradient3939)"/>
        <ellipse cx="192" cy="46" rx="5" ry="6" fill="#999"/>
        </g>
        <g>
        <path d="m 158,64.5 h 30 c 0,0 3,0 3,6 0,7 -5,7 -5,7 l -28,6 z" fill="url(#linearGradient607)"/>
        <ellipse cx="158" cy="74" rx="8" ry="9.5" fill="#b2b2b2"/>
        </g>
        <g>
        <path d="m120 32 32 7s3 0 3 6c0 7-5 7-5 7l-30-1z" fill="#b2b2b2"/>
        <ellipse cx="120" cy="42" rx="8" ry="9.5" fill="#999"/>
        </g>
        <g>
        <path d="m 158,32.5 29,7 c 0,0 3,0 3,6 0,7 -5,7 -5,7 l -27,-1 z" fill="url(#linearGradient3786)"/>
        <ellipse cx="158" cy="42" rx="8" ry="9.5" fill="#b2b2b2"/>
        </g>
        </>):(<></>)}
        <g>
        <path d="m40 15 65 15s5 0 5 10-7 10-7 10l-63-5" fill="url(#linearGradient1192)"/>
        <circle cx="40" cy="30" r="15" fill="url(#linearGradient3658)"/>
        </g>
        <g>
        <path d="m40 65h65s5 0 5 11c0 9-5 9-5 9l-65 10" fill="url(#linearGradient3656)"/>
        <circle cx="40" cy="80" r="15" fill="url(#linearGradient3658)"/>
        </g>
        <g>
        <path d="m90 15 60 15s5 0 5 10-8 10-8 10l-57-5" fill="url(#linearGradient3774)"/>
        <circle cx="90" cy="30" r="15" fill="url(#linearGradient3658)"/>
        </g>
        <g>
        <path d="m90 65h60s5 0 5 9c0 11-5 11-5 11l-60 10" fill="url(#linearGradient3770)"/>
        <circle cx="90" cy="80" r="15" fill="url(#linearGradient3658)"/>
        </g>
        </svg>
    )
}

export default Svg00000057;