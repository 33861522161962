const Svg00000048 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const baseUpdatedHeight = props.baseHeight + Math.max(props.peakHeight1, props.peakHeight2);
    const svgUpdatedHeight = props.scale * baseUpdatedHeight; 
    const chromatoUnit =(h)=>{
        return(
        "m 0,35 c 0,0 0,1 2,1 2,0 2,-2 4,-2 2,0 4,-6 6,-6 2,0 2,9 4,9 2,0 2,-6 4,-6 3,0 4,4 6,4 2,0 2,-2 4,-2 2,0 2,4 5,4 3,0 3,-8 5,-8 2,0 2,9 4,9 1,0 1,-14 3,-14 2,0 3,10 5,10 2,0 2,-9 5,-9 2,0 2,6 4,6 2,0 2,4 4,4 3,0 3,-5 6,-5 3,0 3,-4 6,-4 1,0 1,9 4,9 3,0 3,-5 6,-5 3,0 3,5 8,5 5,0 3,-"+(h+3)+" 5,-"+(h+3)+" 2,0 0,"+(h+3)+" 5,"+(h+3)+" 5,0 4,-2 7,-2 2,0 2,-4 5,-4 3,0 3,8 6,8 1.4,0 2,-6 5,-6 2,0 2,4 5,4 3,0 3,-5 5,-5 2,0 2,6 5,6 2,0 2,-4 5,-4 4,0 4.21,-2 7,-2 2,0 2,5 4,5 2,0 2,-2 5,-2 3,0 3,2 5,2 2,0 2,-5 5,-5 2,0 2,-2 4,-2 2,0 2,7 6,7 1,0 2,-6 3,-6 1,0 2,4 4,4 2,0 3,-1 5,-1 3,0 4,3 4,3"
        )
    }
    const chromatogram1 = chromatoUnit(props.peakHeight1);
    const chromatogram2 = chromatoUnit(props.peakHeight2);
    const chromatogramExtention = chromatoUnit(0);

    return (
        <svg width={svgWidth} height={svgUpdatedHeight} viewBox={"0,"+(-Math.max(props.peakHeight1, props.peakHeight2))+","+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <filter id="filter2072"  colorInterpolationFilters="sRGB">
        <feGaussianBlur result="blur" stdDeviation="2 2"/>
        </filter>
        </defs>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1+200)+",0)"} d={chromatogramExtention} fill="none" stroke={props.background1} strokeLinecap="round" strokeWidth={props.thickness1}/>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1)+",0)"} d={chromatogram1} fill="none" stroke={props.background1} strokeLinecap="round" strokeWidth={props.thickness1}/>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1-200)+",0)"} d={chromatogramExtention} fill="none" stroke={props.background1} strokeLinecap="round" strokeWidth={props.thickness1}/>
        {props.isGlow?(
        <>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1+200)+",0)"} d={chromatogramExtention} fill="none" filter="url(#filter2072)" stroke={props.background1} strokeWidth={props.thickness1}/>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1)+",0)"} d={chromatogram1} fill="none" filter="url(#filter2072)" stroke={props.background1} strokeWidth={props.thickness1}/>
        <path transform={"scale("+(props.zoomX/100)+",1) translate("+(props.peakLoc1-200)+",0)"} d={chromatogramExtention} fill="none" filter="url(#filter2072)" stroke={props.background1} strokeWidth={props.thickness1}/>
        </>):(<></>)}
        {props.isChromato2?(
            <>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2)+",40)"} d={chromatogramExtention} fill="none" stroke={props.background2} strokeLinecap="round" strokeWidth={props.thickness2}/>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2-200)+",40)"} d={chromatogram2} fill="none" stroke={props.background2} strokeLinecap="round" strokeWidth={props.thickness2}/>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2-400)+",40)"} d={chromatogramExtention} fill="none" stroke={props.background2} strokeLinecap="round" strokeWidth={props.thickness2}/>
            {props.isGlow?(
            <>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2)+",40)"} d={chromatogramExtention} fill="none" filter="url(#filter2072)" stroke={props.background2} strokeWidth={props.thickness2}/>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2-200)+",40)"} d={chromatogram2} fill="none" filter="url(#filter2072)" stroke={props.background2} strokeWidth={props.thickness2}/>
            <path transform={"scale("+(-props.zoomX/100)+",1) translate("+(-props.peakLoc2-400)+",40)"} d={chromatogramExtention} fill="none" filter="url(#filter2072)" stroke={props.background2} strokeWidth={props.thickness2}/>
            
            </>):(<></>)}
            </>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000048;