import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000057 from "./Svg00000057.js";
import thumbnail from './Thumbnails/Svg00000057.png';

const Svg00000057Wrap =(props)=> {
  //Name of the image
  const imgName = "QuadrapoleMassSpectrometer";

  //Individual Functions
  const [isTq, setIsTq] = useState (false);
  const [isDetector, setIsDetector] = useState (true);

  //Size of svg image
  const baseWidth = isTq?230:200;
  const baseHeight = 120;

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsTq =()=> {if (isTq) {setIsTq(false);} else {setIsTq(true);}};
  const switchIsDetector =()=> {if (isDetector) {setIsDetector(false);} else {setIsDetector(true);}};
  
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isTq, isDetector, scale)=>{
    return <Svg00000057
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isTq = {isTq}
      isDetector = {isDetector}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Triple Quadrapole")}{'\u00A0'}
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isTq}
                onChange={()=>switchIsTq()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
              {t("Detector")}{'\u00A0'}
              <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isDetector}
                onChange={()=>switchIsDetector()}
              />
              <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isTq, isDetector, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isTq, isDetector, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000057Wrap;
