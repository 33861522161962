const Svg00000038 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * (props.baseHeight+props.componentHeight-90)
    const colorReckon =(cOrg, cAdd)=>{
        let nr = parseInt(cOrg.substring(1,3), 16) - parseInt(cAdd.substring(1,3), 16)<=0?"00":(parseInt(cOrg.substring(1,3), 16) - parseInt(cAdd.substring(1,3), 16)).toString(16).padStart(2, "0");
        let ng = parseInt(cOrg.substring(3,5), 16) - parseInt(cAdd.substring(3,5), 16)<=0?"00":(parseInt(cOrg.substring(3,5), 16) - parseInt(cAdd.substring(3,5), 16)).toString(16).padStart(2, "0");
        let nb = parseInt(cOrg.substring(5,7), 16) - parseInt(cAdd.substring(5,7), 16)<=0?"00":(parseInt(cOrg.substring(5,7), 16) - parseInt(cAdd.substring(5,7), 16)).toString(16).padStart(2, "0");
        return "#"+nr+ng+nb;
    }

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+(props.baseHeight+props.componentHeight-90)+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient5265" x1="0" x2="300" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5266" x1="0" x2="300" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorReckon(props.background3, "#555555")} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5547" x1="0" x2="300" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorReckon(props.background3, "#555555")} offset="0"/>
        <stop stopColor="#aaa" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient903" x1={props.rect1x} x2="300" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1}/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient904" x1={props.rect2x} x2="300" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2}/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        {props.isBottleRack?(
        <path d="m5 25v-20c0-5 5-5 5-5h139s5 0 5 5v20h-5v-20h-139v20z" fill="url(#linearGradient5547)"/>
        ):(<></>)}
        <g>
        <rect x="0" y="25" width="160" height={props.componentHeight} fill="url(#linearGradient5265)"/>
        <rect x={props.rect1x} y={props.rect1y} width={props.rect1w-props.rect1x>0?props.rect1w-props.rect1x:0} height={props.rect1h-props.rect1y<0?0:(props.rect1h>props.componentHeight+25?props.componentHeight+25-props.rect1y:props.rect1h-props.rect1y)} fill="url(#linearGradient903)"/>
        <rect x={props.rect2x} y={props.rect2y} width={props.rect2w-props.rect2x>0?props.rect2w-props.rect2x:0} height={props.rect2h-props.rect2y<0?0:(props.rect2h>props.componentHeight+25?props.componentHeight+25-props.rect2y:props.rect2h-props.rect2y)} fill="url(#linearGradient904)"/>
        </g>
        <path d="m0 25c0-3 5-3 5-3h149s5 0 5 3z" fill="url(#linearGradient5266)"/>
        <path d={"m5 "+(27+props.componentHeight)+"s-5 0-5-3h159c0 3-5 3-5 3z"} fill="url(#linearGradient5266)"/>
        </svg>
    )
}

export default Svg00000038;