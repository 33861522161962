const Svg00000058 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <radialGradient id="radialGradient1445" cx="108" cy="74" r="5" gradientTransform="matrix(2.4 0 0 2.4 -151 -81)" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#b2b2b2" offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1470" x1="85" x2="85" y1="95" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#ccc" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1602" x1="110" x2="110" y1="90" y2="110" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#ccc" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1470-6" x1="170" x2="170" y1="65" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1602-7" x1="170" x2="170" y1="100" y2="160" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g transform={"rotate("+(-props.rotation/2)+", 100, 110)"}>
        <g>
        <path d="m165 65c-25 0-35 20-35 25 0 0-19 4.9-25 5-5 0.11-95 10-95 10s5 5 10 5h85c10 0 25-5 25-5l55-5s10-5 10-15c0-15-10-20-30-20zm0 10c20 0 20 10 20 10s0 10-20 10-20-10-20-10 0-10 20-10z" fill="url(#linearGradient1470)"/>
        <path d="m165 65c-35 0-35 25-35 25v15l55-5s10-5 10-15c0-15-10-20-30-20zm0 10c20 0 20 10 20 10s0 10-20 10-20-10-20-10 0-10 20-10z" fill="url(#linearGradient1470-6)"/>
        </g>
        <g transform={"rotate("+props.rotation+", 100, 110)"}>
        <path d="m25 95c-15 5-15 10-15 10h95c10 0 25 10 25 10s0 20 35 20c30 0 30-20 30-20s0-15-10-15h-55s-15-10-25-10c-5 0-65 0-80 5zm160 20s0 10-20 10-20-10-20-10-0.26-10 20-10c20 0 20 10 20 10z" fill="url(#linearGradient1602)" />
        <path d="m130 115s0 20 35 20c30 0 30-20 30-20s0-15-10-15h-55zm55 0s0 10-20 10-20-10-20-10-0.26-10 20-10c20 0 20 10 20 10z" fill="url(#linearGradient1602-7)" />
        </g>
        <circle cx="110" cy="100" r="5" fill="url(#radialGradient1445)"/>
        </g>
      </svg>
    )
}

export default Svg00000058;